/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-unused-expressions */
import * as Sentry from '@sentry/browser'
import qs from 'query-string'

import { getCaptionDownloads,getVideoDownloadUrls } from 'api'
import config from 'config'

import { APP_HOST } from './constants'
import { trackPageAction } from './metrics'

export const generateStaticUrls = (url) => {
  switch (window.__ENV__) {
    case 'PRODUCTION':
      return "https://static.hyfy.io" + url
    case 'MASTER':
      return "https://static-qa.hyfy.io" + url
    default:
      return "/src/assets" + url
  }
}

export const generateShareUrl = (shareUrl) => {
  // Gets the video id from the share url
  const videoId = shareUrl.slice(0, -1).split('/').pop();
  return `${APP_HOST}/video/share/${videoId}`;
}

export const CHAT_APP_URL = config.APP_URL
  
export const shuffle = (a) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export const formatTime = (timeInSeconds) => {
  const hh = (timeInSeconds / 3600) | 0
  const mm = String(((timeInSeconds % 3600) / 60) | 0).padStart(hh ? 2 : 1, '0')
  const ss = String((timeInSeconds % 3600) % 60 | 0).padStart(2, '0')

  return [hh, mm, ss].filter(Boolean).join(':')
}

export const inititalizeSentry = (env) => {
  Sentry.init({ dsn: 'https://8adc3469bfe74223b4b5048ba9f26673@sentry.io/1436912', environment: env });
}

// See https://stackoverflow.com/a/40547470
export function copyContentToClipboard(element) {
  element.setAttribute('contenteditable', true);

  const selection = window.getSelection();
  const range = document.createRange();
  range.selectNodeContents(element);
  selection.removeAllRanges();
  selection.addRange(range);

  document.execCommand('copy');
  window.getSelection().removeAllRanges();

  element.setAttribute('contenteditable', false);
}

export const createCSVDownload = (csvData, exportName = 'videos_report') => {
  const blobData = new Blob([csvData], { type: 'text/csv' })
  const url = URL.createObjectURL(blobData)
  const encodedUri = encodeURI(url)
  const link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', exportName + '.csv')
  document.body.appendChild(link)
  link.click()
}

export const downloadVideo = async (videoId) => {
  const downloadWindow = window.open()

  try {
    const data = await getVideoDownloadUrls({ videoId })
    const { url, query } = qs.parseUrl(data.url)
    const updatedQuery = { ...query, ...{ download: 1 } }
    const updatedUrl = [url, qs.stringify(updatedQuery)].join('?')

    downloadWindow.location = updatedUrl
    setTimeout(() => downloadWindow.close(), 3000)
  } catch (e) {
    Error(e)
    downloadWindow.close()
  }
}

export const downloadCaptions = async (videoId) => {
  const downloadWindow = window.open()

  try {
    const data = await getCaptionDownloads({ videoId })
    const { url, query } = qs.parseUrl(data.url)
    const updatedQuery = { ...query, ...{ download: 1 } }
    const updatedUrl = [url, qs.stringify(updatedQuery)].join('?')
    downloadWindow.location = updatedUrl
    setTimeout(() => downloadWindow.close(), 3000)
  } catch (e) {
    Error(e)
    downloadWindow.close()
  }
}

// remove these two functions below later after we confirm for a period of time the ones in /metrics work fine
export const track = (event, attrs = {}) => {
  trackPageAction('using old track calls')
  window && window.analytics && window.analytics.track(event, attrs)
}

export const page = (pageName, attrs = {}) => {
  trackPageAction('using old page calls')
  window && window.analytics && window.analytics.page(pageName, attrs)
}

export const isValidEmailOrDomain = (input) => {
  const REGEXP_DOMAIN_STR = '@([a-z0-9]([a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9]([a-z0-9-]*[a-z0-9])?';
  const REGEXP_DOMAIN = new RegExp('^(' + REGEXP_DOMAIN_STR + ')$', 'i');
  const REGEXP_EMAIL_STR = '([a-z0-9.!#$%&\'*+/=?^_`{|}~-]+(?:[a-z0-9.!#$%&\'*+/=?^_`{|}~-]+)*)' + REGEXP_DOMAIN_STR;
  const REGEXP_EMAIL = new RegExp('^(' + REGEXP_EMAIL_STR + ')$', 'i');
  return REGEXP_EMAIL.test(input) || REGEXP_DOMAIN.test(input);
}

// HACK: The <Avatar /> component will generate a deterministic color based
// on id alone, but only when its `email` prop is a non-empty string. This
// seems to be a bug in Tide.
const UNKNOWN_EMAIL = ' '

export const lookupUser = (allUsers, userId) => {
  const matchingUser = allUsers.find(u => u.id === userId)

  if (!matchingUser) {
    return {
      id: userId,
      email: UNKNOWN_EMAIL
    }
  }

  return {
    id: userId,
    name: [matchingUser.firstName, matchingUser.lastName]
      .filter(Boolean)
      .join(' ') || matchingUser.email,
    email: matchingUser.email || UNKNOWN_EMAIL,
    avatarUrl: matchingUser.image,
  }
}

export const openTeamsUpgradePlaybook = () => window.__ENV__ === 'PRODUCTION' ?
    // Opens a playbook where user can book a meeting to upgrade to teams
    window.drift?.api.startInteraction({ interactionId: 137734 }) :
    window.alert('this would be where the driftbot fires on the prod version')

export function popUp({ path, onClose, onMessage, windowName, windowOptions }) {
  const left = window.screenX + (window.innerWidth * 0.03)
  const top = window.screenY + (window.innerHeight * 0.12)
  const width = window.innerWidth * 0.93
  const height = window.innerHeight * 0.92
  
  windowName = windowName || "DriftVideo" // should not include space for IE
  windowOptions = windowOptions || `location=0,status=0,width=${width},height=${height},top=${top},left=${left}`
  onClose = onClose || function() {}
  const _oauthWindow = window.open(path, windowName, windowOptions)

  const _oauthInterval = window.setInterval(function() {
    if (_oauthWindow.closed) {
      window.clearInterval(_oauthInterval)
      onClose()
    }
  }, 1000)

  const eventer = window.addEventListener

  eventer("message", e => {
    if (e.origin !== window.location.origin || e.source !== _oauthWindow)
      return
    !!onMessage && onMessage(e)
  })

  return _oauthWindow
 }

 export const objectOmit = (object, keyToOmit) => Object.fromEntries(
  Object.entries(object)
  .filter(([key]) => ![keyToOmit].includes(key))
);

export const redirectToPlayerPage = () => {
  Error('Authorization error, redirecting')
  window.location = window.location.href.replace('/unified', '').replace('share', 'v')
}

export const fetchRegionCookie = () => {
  const regionCookie = document.cookie.match(new RegExp('DriftRegion=([^;]+)'))
  if (regionCookie) return regionCookie[1]
}
