export const SET_SEARCH_STRING = 'SET_SEARCH_STRING';
export const INCREMENT_PAGE = 'INCREMENT_PAGE';
export const DECREMENT_PAGE = 'DECREMENT_PAGE';
export const RESET_PAGE = 'RESET_PAGE';
export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';
export const SET_SORT_KEY = 'SET_SORT_KEY';
export const SET_SORT_DIRECTION = 'SET_SORT_DIRECTION';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
export const SET_SOURCE_TYPE = 'SET_SOURCE_TYPE';
export const SET_TEAM = 'SET_TEAM';
export const SET_USER_FILTER = 'SET_USER_FILTER';
