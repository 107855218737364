/* eslint-disable no-extra-boolean-cast */
import copy from 'copy-to-clipboard';


export function copyContentToClipboard({ html, text }) {
  copy(text, {
    onCopy: (clipboard: any) => {
      if (!!html) {
        clipboard.setData('text/html', html)
      }
      if (!!text) {
        clipboard.setData('text/plain', text)
      }
    },
  })
}


function getThumbnailDimensions(thumbnailUrl): Promise<{ thumbnailWidth: number; thumbnailHeight: number }> {
  if (!thumbnailUrl) {
    return Promise.resolve({} as any)
  }

  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = thumbnailUrl
    image.onerror = reject

    image.onload = () => {
      const ratio = image.width > 480 ? image.width / 480 : 1

      resolve({
        thumbnailWidth: Math.floor(image.width / ratio),
        thumbnailHeight: Math.floor(image.height / ratio),
      })
    }
  })
}

export default async function copyToClipboard({ publicUrl, thumbnailUrl, videoTitle }) {
  const { thumbnailWidth, thumbnailHeight } = await getThumbnailDimensions(
    thumbnailUrl
  )

  const html = `
    <div>
      <div style="margin-top: 5px;">
        <a href="${publicUrl}">${publicUrl}</a>
      </div>
      ${(thumbnailUrl &&
        `
          <a href="${publicUrl}">
            <img
              style="width: ${thumbnailWidth}px; height: ${thumbnailHeight}px;"
              src="${thumbnailUrl}"
              alt="Drift Video: ${videoTitle}"
            >
          </a>
      `) ||
        ''}
    </div>
  `

  copyContentToClipboard({ html, text: publicUrl })
}

