import { segmentTrack } from 'utils/metrics';

export const VIDEO_APP_URL =
  window.__ENV__ === 'PRODUCTION' ? 'https://video.drift.com' : 'https://app-qa.video.drift.com';

const GOOGLE_OAUTH_CLIENT_ID =
  window.__ENV__ === 'PRODUCTION'
    ? '87492603950-u124kma19e32jh963uc9s3vsuujpm53k.apps.googleusercontent.com'
    : '519208343067-nl6fp3es3cfqifpotab43kjqg7f85ssc.apps.googleusercontent.com';

const MICROSOFT_OAUTH_CLIENT_ID =
  window.__ENV__ === 'PRODUCTION'
    ? '57aa99b9-8cd2-4c0e-9498-2d6749d33f3b'
    : '14eeaf85-cfbb-45c6-84fb-0541d2121f9b';

export const trackEvent = (event, attributes) => {
  segmentTrack(event, { product: 'video', ...attributes });
};

export const popUp = ({ path, onClose }) => {
  onClose = onClose || function () {};
  const windowName = 'ConnectWithOAuth'; // should not include space for IE
  const leftOffset = (window.innerWidth - 700) / 2;
  const topOffset = (window.innerHeight - 650) / 2 + 50;
  const windowOptions = `location=0,status=0,width=700,height=650,left=${leftOffset},top=${topOffset}`;
  const _oauthWindow = window.open(path, windowName, windowOptions);
  const _oauthInterval = window.setInterval(function () {
    if (_oauthWindow && _oauthWindow.closed) {
      window.clearInterval(_oauthInterval);
      onClose();
    }
  }, 500);

  return _oauthWindow;
};

export const encodeQueryData = function (data) {
  const ret = [];
  for (const key in data) {
    const value = data[key];
    if (Array.isArray(value)) {
      value.forEach((element) =>
        ret.push(`${encodeURIComponent(key)}=${encodeURIComponent(element)}`),
      );
    } else if (value != null) {
      ret.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
  }
  return ret.join('&');
};

export const generateGoogleOauthUrl = () => {
  const GOOGLE_OAUTH_BASE_URL = 'https://accounts.google.com/o/oauth2/v2/auth';

  const params = {
    redirect_uri: `${VIDEO_APP_URL}/settings/accept-integration/google-calendar`,
    client_id: GOOGLE_OAUTH_CLIENT_ID,
    response_type: 'code',
    access_type: 'offline',
    include_granted_scopes: true,
    scope: 'https://www.googleapis.com/auth/calendar',
  };

  return `${GOOGLE_OAUTH_BASE_URL}?${encodeQueryData(params)}`;
};

export const generateMicrosoftOauthUrl = () => {
  const MICROSOFT_OAUTH_BASE_URL = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';

  const params = {
    response_type: 'code',
    response_mode: 'query',
    redirect_uri: `${VIDEO_APP_URL}/settings/accept-integration/office-365-calendar`,
    client_id: MICROSOFT_OAUTH_CLIENT_ID,
    scope: 'openid profile offline_access User.Read Calendars.ReadWrite',
  };

  return `${MICROSOFT_OAUTH_BASE_URL}?${encodeQueryData(params)}`;
};

export const ConnectCalendarModalState = {
  SELECT_CALENDAR_PROVIDER: 0,
  SELECT_CALENDAR: 1,
  SUCCESS: 2,
};

export const STATE_ORDER = [
  ConnectCalendarModalState.SELECT_CALENDAR_PROVIDER,
  ConnectCalendarModalState.SELECT_CALENDAR,
  ConnectCalendarModalState.SUCCESS,
];
