import { PlayerOptions, UserCustomizations,VideoSource } from 'types/interfaces';
import { VideoJsPlayer } from 'video.js';

type Kind = 'subtitles' | 'captions' | 'descriptions' | 'chapters' | 'metadata';
type Mode = 'disabled' | 'hidden' | 'showing';
interface VTTSource {
  src: string;
  kind: Kind;
  label: string;
  srclang: string;
  default: boolean;
  mode: Mode;
}

export const getVttFileSources = (videoSources: VideoSource[] = []) => {
  const TRANSCRIBED_FILE_FORMAT = 'text/vtt';
  const resultingVttSources: VTTSource[] = [];
  if (videoSources) {
    const vttSources = videoSources.filter(function (source) {
      return source.type === TRANSCRIBED_FILE_FORMAT && !source.isPreview;
    });

    vttSources.forEach(function (vttSource) {
      const source = {} as VTTSource;
      source.src = vttSource.src;
      source.kind = 'captions';
      source.label = 'English';
      source.srclang = 'en';
      source.default = true;
      resultingVttSources.push(source);
    });
  }
  return resultingVttSources;
};

export const addCaptionsToPlayer = (
  player: VideoJsPlayer,
  videoSources: VideoSource[],
  hasCaptionsFeature: boolean,
  onCaptionsLoaded: () => void,
) => {
  player.on('loadedmetadata', function () {
    const vttSources = getVttFileSources(videoSources);
    if (vttSources.length > 0 && hasCaptionsFeature) {
      const newTrack = { ...vttSources[0], mode: 'hidden' } as VTTSource;
      const track = player.addRemoteTextTrack(newTrack, true);
      track.addEventListener('load', () => {
        onCaptionsLoaded();
      });
    }
  });
};

export const addPreviewThumbnailsToPlayer = (
  player: VideoJsPlayer,
  videoSources: VideoSource[],
  startTime: number = 0,
) => {
  player.on('loadedmetadata', function () {
    const preview = videoSources.find((src) => src.isPreview);

    if (preview) {
      player.addRemoteTextTrack({ kind: 'metadata', src: preview.src }, true);
      player.trigger('addPreviewTrack', startTime);
    }
  });
};

export const ControlBarChildren = [
  'playToggle',
  'volumePanel',
  'currentTimeDisplay',
  'timeDivider',
  'durationDisplay',
  'progressControl',
  'liveDisplay',
  'seekToLive',
  'remainingTimeDisplay',
  'customControlSpacer',
  'subsCapsButton',
  'playbackRateMenuButton',
  'chaptersButton',
  'descriptionsButton',
  'audioTrackButton',
  'fullscreenToggle',
];

export const getCaptionsSource = (videoSources: VideoSource[]) =>
  videoSources.filter((src) => src.type === 'text/vtt');

export const captionsGenerated = (videoSources: VideoSource[]) =>
  !!getCaptionsSource(videoSources).length;

  export const shouldShowCaptions = (
  userCustomizations: UserCustomizations,
  playerOptions: PlayerOptions,
) => {
  return playerOptions?.has_captions === undefined
    ? !userCustomizations?.disableCaptions
    : playerOptions?.has_captions;
};
