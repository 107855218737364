import qs from 'query-string';

import { objectOmit } from 'utils';
import {
  ControlBarChildren,
} from 'utils/playerUtils';

export const MEDIA_ERR_SRC_NOT_SUPPORTED = 4;
export const MEDIA_ERR_NETWORK = 2;
export const VIDEO_SOURCE_ERROR_MAX_RETRY = 3;

export const isPartiallyTranscodedVideo = (player) =>
  player.currentSrc() && player.currentSrc().includes('-2.mp4');

export const replacePlayerWithNewSource = (player, src) => {
  const paused = player.paused();
  const currentTime = player.currentTime();
  player.src(src);

  player.on('loadedmetadata', function () {
    if (!paused) {
      player.one('play', function () {
        setTimeout(() => {
          player.currentTime(currentTime);
        }, 100);
      });
      player.play();
    }
  });

  player.load();
};

export const generateOptions = (isMobile) => {
  const defaults = {
    autoplay: true,
    controls: true,
    fill: true,
    playbackRates: [0.5, 0.8, 1, 1.2, 1.3, 1.5, 2],
    controlBar: {
      pictureInPictureToggle: false,
      children: ControlBarChildren,
    },
    plugins: {
      thumbnails: {},
    },
  };

  if (!isMobile) {
    defaults.plugins.hotkeys = {
      volumeStep: 0.1,
      seekStep: 5,
      enableModifiersForNumbers: false,
      enableVolumeScroll: false,
    };
  }

  return defaults;
};

export const prioritizeMP4 = (videoSources = []) => {
  return videoSources.sort((a, b) => {
    if (a.type === 'video/mp4') {
      return -1;
    }
    if (b.type === 'video/mp4') {
      return 1;
    }
    return 0;
  });
};

export const generateEmojiButtonPayload = (emoji, videoTitle, autoAssigneeId, visibility) => {
  return {
    body: `<span>${emoji}</span>`,
    attributes: {
      autoAssigneeId: autoAssigneeId,
      widgetVersion: 1,
      driftVideoMode: true,
      isTestingConversation: false,
      visibility: visibility,
      clientTimestamp: Date.now(),
      videoConvoContext: {
        title: videoTitle,
        link: window.location.href,
      },
    },
  };
};

export const updatePlayerURLWithQueryParams = (title, shouldShowMeeting) => {
  const { url, query } = qs.parseUrl(window.location.href);

  const updatedQuery = shouldShowMeeting
    ? { ...query, ...{ bookMeeting: true } }
    : objectOmit(query, 'bookMeeting');

  const updatedUrl = [url, qs.stringify(updatedQuery)].filter(Boolean).join('?');
  window.history.replaceState(null, title, updatedUrl);
};
