declare global {
  interface Window {
    hasChatSeat: boolean
    disable_messaging: boolean
    fail_chat_silently: boolean
    has_team_bot_meetings: boolean
    disable_branding: boolean
    calendar_connection_status: boolean
    videoId: number
    drift_agent_id: number
    jwt: string
    viewer: string
    orgId: number
    is_viewing_own_video: boolean
    is_viewing_same_org_video: boolean
    viewId: number
    userId: number
    use_v2_widget: boolean
    __ENV__: string
    drift: any
    newrelic: any
    analytics: any
    driftBilling: any
  }
}

export interface VideoPermission {
  source: string,
  value: object // tbd
}

export interface PrivacyGroup {
  id: number,
  teamName: string
}

export interface Thumbnails {
  default: boolean,
  type: string, // could be enum later
  src: string
}

export interface UploadedThumbnailFile extends File {
  preview: string
}

export interface UploadedFile extends File {
  preview: string
}

export interface VideoSource {
  id: number
  height: number
  src: string
  stream: number
  type: string
  width: number
  isPreview?: boolean
}

export interface VideoData {
  thumbnail?: string
  thumbnails: Thumbnails[] //tbd
  publicUrl: string
  defaultThumbail: string,
  defaultThumbailWithPlay: string,
  isAudioIncluded: boolean,
  thumbnailUrl: string,
  videoId: number,
  videoCreator: VideoCreator
  videoUrlString: string
  title: string
  videoSources: VideoSource[]
  videoVisibility: { visibleToAll: boolean }
}

export interface VideoCreator {
  driftUserId: number,
  id: number
  image: string,
  name: string
}

export interface PlayerOptions {
  disable_chat?: boolean,
  bot_meeting_playbook_enabled?: boolean,
  show_created_at_date?: boolean,
  thumbnail_key?: boolean
  has_captions?: boolean
}

export interface UserCustomizations {
  darkMode?: boolean
  disableCaptions?: boolean
  notificationSettings?: NotifSettings
  conversationVisibility?: string
  initialVideoVisibility?: any
  logo?: string
  logoUrl?: string
}

export interface NotifSettings {
  email: boolean,
  pushNotifications: boolean,
  teamNotifications: boolean,
  weeklyAdmin: boolean,
}

enum USER_SUBSCRIPTIONS_PLANS {
  HYFY_FREE = "HYFY_FREE",
  HYFY_PRO = "HYFY_PRO",
  DRIFT_TEAM = "DRIFT_TEAM"
}

interface UserSubscriptionPlan {
  planId: USER_SUBSCRIPTIONS_PLANS
  paid: boolean
  subscriptionType: string
  name: USER_SUBSCRIPTIONS_PLANS
  expiresInDays: number
  expiresOn: string
  status: string
}

export interface User {
  id: number,
  email: string,
  name?: string,
  image?: string,
  avatarUrl?: string,
  daysFromSignup?: number,
  hasCompletedOnboarding?: boolean,
  subscription: {
    userFeatures: string[],
    hasFreeSubscription: boolean,
    userSubscriptions: {
      [planName in USER_SUBSCRIPTIONS_PLANS]: UserSubscriptionPlan
    }
  },
  driftUserData: DriftUserData
}

export interface DriftUserData {
  hasTeamManagement: boolean
  hasModifyWelcomeMessagePermission: boolean
  hasChatSeat: boolean
  hasVideoUpload: boolean
  hasVideoPrivacy: boolean
  hasPaidVideo: boolean
  hasVideoCustomization: boolean
  hasStorageChoice: boolean
  hasDriftVideoTrial: boolean
  hasDriftVideoTeamTrial: boolean
  driftEmbed: string
  driftAgentId: number
  orgId: number
  hasTouchlessVideoUpgrade: boolean
  canInviteTeammates: boolean
}

export interface DriftUser {
  alias: string,
  availability: string,
  avatarUrl: string,
  avatars: {},
  bot: boolean,
  createdAt: number,
  email: string,
  gates: [],
  id: number,
  multiOrgRoles: {},
  name: string,
  orgId: number,
  profile: {},
  role: string,
  roles: [],
  scopes: [],
  settings: {},
  status: string,
  type: string,
  updatedAt: number,
  userJwt: string,
}

export enum PERMISSION_LEVELS {
  READ = 'READ',
  WRITE = 'WRITE'
}

export interface EducationItem {
  id: number,
  name: string
}

export interface Folder {
  id: number
  title: string
  parentFolder?: number
  privacyStatus: PrivacyStatus
  readonly?: boolean
}

export interface PlaceholderFolder {
  id: undefined
  title: string
  parentFolder: null
  privacyStatus: PrivacyStatus
  readonly?: boolean
}

export enum PrivacyStatus {
  Individual = 1,
  Team = 2,
}


interface SocialAccountSet {
  id: number;
  token: string;
  tokenSecret: string;
  expiresAt: string;
  app: number;
  account: number;
}

enum SOCIAL_ACCOUNT_PROVIDER {
  DRIFT = 'DRIFT',
  DRIFTQA = 'DRIFTQA',
  AUTH0 = 'AUTH0',
}

interface SocialAccount {
  id: number;
  socialTokenSet: SocialAccountSet[];
  provider: SOCIAL_ACCOUNT_PROVIDER;
  uid: string;
  lastLogin: string;
  dateJoined: string;
  extraData: Object;
  user: number;
}

export interface UserData {
  pk: number;
  firstName: string;
  lastName: string;
  email: string;
  image: string;
  lastLogin: null;
  type: number; // should probably enum with the hyfy user types
  datetimeCreated: string;
  datetimeUpdated: string;
  socialAccountSet: SocialAccount[];
  subscription: {
    hasTouchlessUpgrade: boolean;
    hasFreeSubscription: boolean;
    hasPaidLicenseSubscription: boolean;
    userFeatures: string[]; // could also probably define this as an enum for the future
    userBetaFeatures: string[];
    userSubscriptions: {
      [planName in USER_SUBSCRIPTIONS_PLANS]: UserSubscriptionPlan;
    };
    expiredSubscriptions: {
      [planName in USER_SUBSCRIPTIONS_PLANS]: UserSubscriptionPlan;
    };
    suspendedSubscriptions: {
      [planName in USER_SUBSCRIPTIONS_PLANS]: UserSubscriptionPlan;
    };
    cancelledSubscriptions: {
      [planName in USER_SUBSCRIPTIONS_PLANS]: UserSubscriptionPlan;
    };
    analyticsPlan: string;
    analyticsPlanDetails: string;
    upgradeDateUtc: number;
    subscriptionStartDate: string;
    subscriptionEndDate: string;
    daysFromUpgrade: number;
  };
  driftUserData: DriftUserData;
  daysFromSignup: number;
  domain: string;
  domainType: string;
  hasCompletedOnboarding: boolean;
  storageId: number;
}

export enum CONVERSATION_VISIBILITY {
  PUBLIC = 'PUBLIC',
  PARTICIPANTS_ONLY = 'PARTICIPANTS_ONLY',
}
