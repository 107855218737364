export const SOURCE_TYPE_VIDEO = 1;
export const SOURCE_TYPE_GIF = 2;
export const SOURCE_TYPE_MEETING_RECORDING = 3;

export const DND_FOLDER = 'DND_FOLDER';
export const DND_VIDEO = 'DND_VIDEO';

export const VIDEO_SOURCE_TYPES = [
  {
    label: 'All recordings',
    value: null,
  },
  {
    label: 'Video',
    value: SOURCE_TYPE_VIDEO,
  },
  {
    label: 'GIF',
    value: SOURCE_TYPE_GIF,
  },
  {
    label: 'Zoom recordings',
    value: SOURCE_TYPE_MEETING_RECORDING,
  },
];

const FILE_FORMATS = {
  OGG: 1,
  WEBM: 2,
  MP4: 3,
  GIF: 4,
  VTT: 5,
  MOV: 6,
};

const VIDEO_FORMATS = {
  THEORA: 1,
  VP8: 2,
  VP9: 3,
  H264: 4,
};

export const FILE_AND_VIDEO_FORMATS = {
  'video/webm': {
    fileType: FILE_FORMATS['WEBM'],
    videoType: VIDEO_FORMATS['VP9'],
    audioMuxedType: '.webm',
  },
  'video/mp4': {
    fileType: FILE_FORMATS['MP4'],
    videoType: VIDEO_FORMATS['H264'],
    audioMuxedType: '.mp4',
  },
  'video/quicktime': {
    fileType: FILE_FORMATS['MOV'],
    videoType: VIDEO_FORMATS['H264'],
    audioMuxedType: '.mov',
  },
};

export const API_URLS = {
  videos: {
    update: (videoId) => `/apis/v1/videos/${videoId}/`,
    // download: '"/apis/v1/videos/"+ arguments[0] +"/get_download_urls/"',
    list: '/apis/v1/videos/',
    // get: '"/apis/v1/videos/"+ arguments[0] + "/"',
    uploadInfo: (videoId) => `/apis/v1/videos/${videoId}/upload_info/`,
    uploadToken: (videoId) => `/apis/v1/videos/${videoId}/upload_token/`,
  },
  videoSources: {
    // list: '"/apis/v1/video-sources/"',
    update: (videoSourceId) => `/apis/v1/video-sources/${videoSourceId}/`,
    // uploadUrl: '"/apis/v1/video-sources/" + arguments[0] + "/upload_url/"',
    // uploadInfo: '"/apis/v1/video-sources/" + arguments[0] + "/upload_info/"'
  },
};

export const VIDEO_STATUS = {
  NEW: 1,
  DRAFT: 2,
  UPLOADED: 3,
  DELETED: 4,
  EXPIRED: 5,
  FAILED: 6,
  BLOCKED: 7,
};

export const SOURCE_TYPE = {
  VIDEO: 1,
  GIF: 2,
  MEETING_RECORDING: 3,
};

export const STREAM_TYPE = {
  SCREEN: 1,
  FACECAM: 2,
};

export const VIDEO_PLAY_MODE = {
  FACECAM_SCREEN: '1',
  FACECAM_ONLY: '2',
  SCREEN_ONLY: '3',
};

export const UPLOAD_SOURCE = {
  EXTENSION: 1,
  USER: 2,
};

export const EDIT_CONTROL_TYPE = {
  PRIVACY: 'privacy',
  AUDIO: 'audio',
  THUMBNAILS: 'thumbnails',
  BOT_PLAYBOOK_CONTROLS: 'bot-playbook-controls',
  TRIM_VIDEO: 'trim-video',
  AUDIO_AND_CAPTIONS: 'audio_and_captions',
};

export const VIDEO_FILE_NAME_PREFIX = 'HYFY-RECORDING-';

const EXTENSION_ID_OPTIONS = {
  PRODUCTION: 'kfhkikpdmehlpkaiplafjkaicdljldcf',
  MASTER: 'lbkepapcgenekcckmaailciejbdpgedb',
  ___DRIFT_ENV: 'apmijipmegididofngdjejpoahgnblji',
};

const NEW_EXTENSION_ID_OPTIONS = {
  PRODUCTION: 'kfhkikpdmehlpkaiplafjkaicdljldcf',
  MASTER: 'jmncadimlpigibdhcojgjodiefpmjjdb',
  ___DRIFT_ENV: 'mfcmapnobojbckjmmlconlpjioodeobn',
};

export const EXTENSION_ID = EXTENSION_ID_OPTIONS.PRODUCTION;
export const NEW_EXTENSION_ID = NEW_EXTENSION_ID_OPTIONS.PRODUCTION;

export const EXTENSION_URL = `https://chrome.google.com/webstore/detail/${EXTENSION_ID_OPTIONS.PRODUCTION}`;
export const NEW_EXTENSION_URL = `https://chrome.google.com/webstore/detail/${NEW_EXTENSION_ID_OPTIONS.PRODUCTION}`;

const AUTH_CLIENT_ID = {
  PRODUCTION: '4p2iyyu7m8swh6',
  MASTER: '7m4k8pi4v8be53',
  ___DRIFT_ENV: 'yxb9v9kgtkn6wklocal',
};

// @ts-ignore
export const CLIENT_ID = AUTH_CLIENT_ID[window.__ENV__ || '___DRIFT_ENV'];

const APP_HOSTS = {
  PRODUCTION: 'https://app.drift.com',
  MASTER: 'https://app.driftqa.com',
  ___DRIFT_ENV: 'http://localhost:3100',
};

// @ts-ignore
export const APP_HOST = APP_HOSTS[window.__ENV__ || '___DRIFT_ENV'];

export const pageSize = 5;

export const msPerMinute = 60 * 1000;
export const msPerHour = msPerMinute * 60;
export const msPerDay = msPerHour * 24;
export const msPerMonth = msPerDay * 30;
export const msPerYear = msPerDay * 365;
