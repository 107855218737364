import { fetchTeam } from 'api';
export const parseDateToRange = (startDate, endDate) => {
  if (startDate.isSame(endDate)) {
    return startDate.format('MMMM D, YYYY');
  }

  const startDateFormat = `MMMM D${startDate.isSame(endDate, 'year') ? '' : ', YYYY'}`;
  const endDateFormat = startDate.isSame(endDate, 'month') ? 'D, YYYY' : 'MMMM D, YYYY';
  return `${startDate.format(startDateFormat)} – ${endDate.format(endDateFormat)}`;
};

export const fetchAndReturnCurrentTeamUsers = (userIds) => {
  return fetchTeam().then((response) => {
    if (response) {
      // Initial state of the "created by" filter
      const options = response.map((user) => {
        return {
          label: user.firstName !== '' ? `${user.firstName} ${user.lastName}` : `${user.email}`,
          value: user.id,
        };
      });

      let users = '';
      const selectedUsers = userIds ? userIds.split(',') : '';
      if (selectedUsers) {
        const currentUsers = response
          .filter((user) => selectedUsers.includes(user.id.toString()))
          .map((user) => {
            return {
              label: user.firstName !== '' ? `${user.firstName} ${user.lastName}` : `${user.email}`,
              value: user.id,
            };
          });
        users = currentUsers;
      }

      return {
        options,
        users,
      };
    }
  });
};

export const friendlyFilterText = {
  min_video_views: 'Views are greater than ',
  max_video_views: 'Views are less than ',
  min_meetings_booked: 'Meetings booked is greater than ',
  max_meetings_booked: 'Meetings booked is less than ',
  user_ids: 'Created by ',
};

export const determineChipFilters = (filterMap, createdByUsers) => {
  const nextFilters = [];

  for (const [key, value] of Object.entries(filterMap)) {
    if (value === '') {
      continue;
    }

    if (key === 'user_ids') {
      (createdByUsers || []).forEach((user) => {
        nextFilters.push({
          key,
          value: user.value,
          label: `${friendlyFilterText[key]}${user.label}`,
        });
      });
    } else {
      nextFilters.push({
        key,
        value,
        label: `${friendlyFilterText[key]}${value}`,
      });
    }
  }

  return nextFilters;
};
