import { Folder, PlaceholderFolder, PrivacyStatus } from '../types/interfaces'

// We can't always know the id if the root Personal Library folder (it might
// not exist yet!) so this uniquely identifies it using other properties.
export function isRootPersonalLibraryFolder(
  folder: Folder | PlaceholderFolder
): boolean {
  return (
    folder.parentFolder === null &&
    folder.privacyStatus === 1 &&
    !folder.readonly
  )
}

// Similar to the above, this identifies the root "Team Library" folder in the
// absence of a known id.
export function isRootTeamLibraryFolder(
  folder: Folder | PlaceholderFolder
): boolean {
  return (
    folder.parentFolder === null &&
    folder.privacyStatus === 2 &&
    !folder.readonly
  )
}

export function isHowToVideosFolder(
  folder: Folder | PlaceholderFolder
): boolean {
  return folder.parentFolder === null && folder.readonly === true
}

// These objects can be used in lieu of real folders (i.e. ones we've fetched
// from /apis/v1/folders/) either while the app is loading or when these folders
// haven't actually be created (i.e. read) yet.
export const placeholderRootPersonalLibraryFolder: PlaceholderFolder = {
  id: undefined,
  title: 'Personal library',
  privacyStatus: 1,
  parentFolder: null,
  readonly: false,
}

export const placeholderRootTeamLibraryFolder: PlaceholderFolder = {
  id: undefined,
  title: 'Team library',
  privacyStatus: 2,
  parentFolder: null,
  readonly: false,
}

export const placeholderHowToVideosFolder: PlaceholderFolder = {
  id: undefined,
  title: 'How to use Drift Video',
  privacyStatus: 1,
  parentFolder: null,
  readonly: true,
}

// Finds a folder given its id and the flat list of folders.
export function findFolderById(id: number, folders: Folder[]): Folder | null {
  return folders.find(f => f.id === id) || null
}

// Given a folder and the flat list of folders, returns the path of IDs leading
// up to and including that folder.
export function getFolderPath(folder: Folder | null, folders: Folder[]): number[] {
  if (!folder) {
    return []
  }

  const path: number[] = []
  const find = id => findFolderById(id, folders)

  for (let f = find(folder.id); f !== null; f = find(f.parentFolder)) {
    path.unshift(f.id)
  }

  return path
}

// Given a folder, returns a relative URL which can be understood by the router.
export function getFolderRelativeUrl(folder: Folder): string {
  if (isRootPersonalLibraryFolder(folder)) {
    return '/video'
  }

  if (isRootTeamLibraryFolder(folder)) {
    return '/video/team/'
  }

  const { id, privacyStatus } = folder
  return `/video/${
    privacyStatus === PrivacyStatus.Team ? 'team/' : ''
    }?folder_id=${id}`
}

// Returns true if `nextParent` is a valid destination for `folder`.
export function canMoveFolder(
  folder: Folder,
  nextParent: Folder,
  folders: Folder[]
): boolean {
  // Dropping into the folder we're already in would be redundant.
  if (folder.parentFolder === nextParent.id) {
    return false
  }

  const pathToNextParent = getFolderPath(nextParent, folders)

  // It's not ok to drop a folder onto any descendant of itself. That
  // will cause react-sortable-tree to go into an infinite loop!
  if (pathToNextParent.includes(folder?.id)) {
    return false
  }

  // For now we are not allowing folders to be moved between the Personal
  // Library and Team Library. In other words, you can't change a folder's
  // visibility by moving it.
  if (folder.privacyStatus !== nextParent.privacyStatus) {
    return false
  }

  return true
}
