import { EXTENSION_ID, NEW_EXTENSION_ID } from 'utils/constants';

export const checkForExtension = () => {
  return new Promise<boolean>((resolve, reject) => {
    if (navigator.userAgent.toLowerCase().includes('chrome')) {
      window.chrome.runtime &&
        window.chrome.runtime.sendMessage(EXTENSION_ID, { message: 'ping' }, (response) => {
          if (response && response.res && response.res === 'pong') {
            resolve(true);
          }
        });
    } else {
      resolve(false);
    }
  });
};

export const checkForNewExtension = () => {
  return new Promise<boolean>((resolve, reject) => {
    if (navigator.userAgent.toLowerCase().includes('chrome')) {
      window.chrome.runtime &&
        window.chrome.runtime.sendMessage(NEW_EXTENSION_ID, { type: 'PING' }, (response) => {
          if (response && response.res && response.res === 'pong') {
            resolve(true);
          }
        });
    } else {
      resolve(false);
    }
  });
};

export const sendTranscriptToExtension = (transcript, videoTitle) => {
  const hasTranscript = transcript !== '';
  const recordingNotesText = hasTranscript
    ? transcript
    : `Sorry, we couldn't generate a script for this video! Use this space to add in notes to record your version of ${videoTitle}.
      Click anywhere to start typing.`;
  window.chrome.runtime.sendMessage(EXTENSION_ID, {
    message: 'setRecordingNotes',
    recordingNotesText,
    openOverlay: hasTranscript,
  });
};

export const openExtensionRecordingModal = (whichExtension: 'old' | 'new' | null) => {
  switch (whichExtension) {
    case 'old':
      window.chrome.runtime.sendMessage(EXTENSION_ID, {
        message: 'openRecordingModal',
        recordingAttemptAttributes: {
          recordingStartSource: 'LIBRARY',
          modalTabState: 'record',
        },
      });
      break;
    case 'new':
      window.chrome.runtime.sendMessage(NEW_EXTENSION_ID, {
        type: 'OPEN_RECORDING_MODAL',
        data: {
          recordingStartSource: 'LIBRARY',
          modalTabState: 'record',
        },
      });
      break;
  }
};
