import axios from 'axios';

import { DriftAnalytics } from '@driftt/dds.analytics';

import config from 'config';

const METRICS_PREFIX = 'video.web.';
const UPLOAD_INTERVAL = 10 * 1000;

let _bufferedMetrics = [] as any[];

const metricsApi = axios.create({
  baseURL: config.METRICS_API,
});

metricsApi.interceptors.response.use(
  (response) => response,
  (error) => {
    DriftAnalytics.captureException(new Error('metric api failure'), { extra: { error } });
    return Promise.resolve();
  },
);
export const sendBulkMetrics = (bulkMetrics) =>
  metricsApi.post('/monitoring/metrics/add/bulk', bulkMetrics);

export const handleFilterString = (filterMap) => {
  return Object.keys(filterMap).length !== 0
    ? ':' +
        Object.entries(filterMap)
          .map(([key, value]) => `${key}=${value}`)
          .join(';')
    : '';
};

export const sendDuration = (duration, metricBaseName, filterMap = {}) => {
  const filterString = handleFilterString(filterMap);
  const metricName = `${METRICS_PREFIX}${metricBaseName}${filterString}`;
  const metric = {
    metric: metricName,
    value: duration,
    dimensions: {
      type: 'bucket',
      buckets: '1000, 2000, 3000, 5000, 7000, 10000, 15000, 30000',
    },
  };
  _bufferedMetrics.push(metric);
};

export const sendCount = (metricBaseName, filterMap = {}) => {
  const filterString = handleFilterString(filterMap);
  const metricName = `${METRICS_PREFIX}${metricBaseName}${filterString}`;
  const metric = {
    metric: metricName,
    value: 1,
    dimensions: {
      type: 'counter',
    },
  };
  _bufferedMetrics.push(metric);
};

export const addCustomAttribute = (key, value) => {
  if (!key || !value) return;
  if (typeof window.newrelic == 'object') {
    window.newrelic.setCustomAttribute(key, value);
  }
};

export const trackPageAction = (actionName, attributes = {}) => {
  if (window.newrelic) {
    window.newrelic.addPageAction(actionName, attributes);
  }
};

export const generateDurationSender = (metricName, filterMap = {}) => {
  const startTime = Date.now();
  return () => {
    return sendDuration(Date.now() - startTime, metricName, filterMap);
  };
};

const _uploadMetrics = () => {
  if (_bufferedMetrics.length > 0) {
    sendBulkMetrics(_bufferedMetrics).then(() => {
      _bufferedMetrics = [];
    });
  }
};

const _startMetrics = () => {
  setInterval(_uploadMetrics, UPLOAD_INTERVAL);
};
_startMetrics();

export const segmentTrack = (event, attrs = {}) => window?.analytics?.track(event, attrs);

export const segmentPage = (pageName, attrs = {}) => window?.analytics?.page(pageName, attrs);
